.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-in-out {
  animation: fade-in-out 3s ease-in-out infinite;
}


@keyframes fade-in-out {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-slide {
  display: flex;
  /* width: calc(200% + 2rem);  */
  animation: slide 20s linear infinite;
}

@media screen and (min-width: 1280px) {
  .container {
    width: 90vw; /* Adjust for 90% of the viewport width */
  }
}

@media screen and (min-width: 1536px) {
  .container {
    width: 100vw; /* Adjust for 100% of the viewport width */
  }
}

/* Ensure the submenu doesn't expand beyond the Category section */
.group:hover .submenu {
  position: absolute;
  /* top: 100%; */
  left: 0;
  width: 200px; /* Adjust width to fit nicely under Category */
  background-color: white;
  color: black;
  padding: 0px 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Ensure the submenu stays above other content */
}

/* Styling for individual submenu items */
.submenu-item {
  padding: 8px 16px;
  text-align: left;
  display: block;
  font-size: 15px;
  color: black;
  text-decoration: none;
}

/* Hover effect for submenu items */
.submenu-item:hover {
  background-color: black;
  color: white;
}

/* Adjust the Category menu item to not expand when hovered */
.group:hover > .menu-link {
  background-color: transparent;
}
